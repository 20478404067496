import React from 'react';
import cx from 'classnames';

const BenefitsOfficeCardsWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
    const className = cx({
        'benefits-office-cards': true,
        'benefits-office-cards--two': React.Children.toArray(children).length === 2,
    });
    return (
        <div className={className}>
            <div className="benefits-office-cards__inner">{children}</div>
        </div>
    );
};

export default BenefitsOfficeCardsWrapper;
