import React from 'react';
import Head from 'next/head';
import Page from 'components/page/page';
import { IconDefs } from 'components/icon/icon';
import PageSeo, { PageSEOPropsType } from 'components/page-seo/page-seo';
import PageHeader from 'components/page-header/page-header';
import PageMobilePanel from 'components/page-mobile-panel/page-mobile-panel';
import PageFooter from 'components/page-footer/page-footer';
import PageDynamicFooter from 'components/page-footer/page-footer-dynamic';
import PageCookie from 'components/page-cookie/page-cookie';
import Modal from 'components/modal/modal';
import ModalContext from 'components/modal-context';
import { handleCookieConsent, onUsePrivacyManagerHandler } from 'lib/cookie-consent';
import { Maybe } from 'interfaces/team-bumble-microsite-cms';

type PageTemplateProps = {
    children?: React.ReactNode;
    // modal?: React.ReactNode;
    browserTab?: string;
    // seoTitle?: string;
    seoDescription?: string;
    // seoUrl?: string;
    // openTitle?: string;
    // openDescription?: string;
    // openImageUrl?: string;
    // openImageAlt?: string;
    // openSiteName?: string;
    hasFooterCta?: boolean;
    footerTitleText?: Maybe<string> | undefined;
    hasFooterCtaButton?: boolean;
    ctaButtonText?: Maybe<string> | undefined;
    ctaButtonUrl?: Maybe<string> | undefined;
    ctaButtonTargetNewWindow?: boolean;
    onPageClick?: () => void;
    dynamicFooter?: boolean;
} & PageSEOPropsType;

const PageTemplate: React.FC<PageTemplateProps> = (props) => {
    const {
        hasFooterCta = true,
        onPageClick,
        browserTab,
        seoDescription,
        footerTitleText,
        hasFooterCtaButton,
        ctaButtonText,
        ctaButtonUrl,
        ctaButtonTargetNewWindow,
        dynamicFooter,
    } = props;
    const { modalContent, modalOpen, modalColor, onModalClose } = React.useContext(ModalContext);

    React.useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            const listenerId = __tcfapi?.('addEventListener', 2, handleCookieConsent);

            return () => {
                if (listenerId) {
                    __tcfapi?.('removeEentListener', 2, null, listenerId);
                }
            };
        } else {
            return;
        }
    }, []);
    React.useEffect(onUsePrivacyManagerHandler, []);

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, viewport-fit=cover"
                />
                <script async={true} src="/viewport-units-buggyfill.js" />
                <link
                    id="deferred-styles"
                    rel="preload"
                    href="/fonts/Circular20/fonts-circular20.css"
                    as="style"
                />
                <link
                    id="deferred-styles"
                    rel="preload"
                    href="/fonts/CircularXX/fonts-circularXX.css"
                    as="style"
                />
            </Head>
            <PageSeo browserTab={browserTab} seoDescription={seoDescription} />

            <Page
                header={<PageHeader />}
                content={props.children}
                footer={
                    dynamicFooter ? (
                        <PageDynamicFooter
                            hasCta={hasFooterCta}
                            footerTitleText={footerTitleText}
                            hasFooterCtaButton={hasFooterCtaButton}
                            ctaButtonText={ctaButtonText}
                            ctaButtonUrl={ctaButtonUrl}
                            ctaButtonTargetNewWindow={ctaButtonTargetNewWindow}
                        />
                    ) : (
                        <PageFooter hasCta={hasFooterCta} />
                    )
                }
                cookieNotification={<PageCookie />}
                modal={
                    <Modal isActive={modalOpen} onClose={onModalClose} color={modalColor}>
                        {modalContent}
                    </Modal>
                }
                mobilePanel={<PageMobilePanel />}
                onPageClick={onPageClick}
            />

            {<IconDefs />}
        </>
    );
};

export default PageTemplate;
